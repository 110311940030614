.GlobalSearchContainer{
    height: 90vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
        height: 6px;
        width: 6px; /* width of the entire scrollbar */
      }
    
      &::-webkit-scrollbar-track {
        background: rgb(203, 203, 203); /* color of the tracking area */
        border-radius: 20px; /* roundness of the scroll thumb */
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: rgb(68, 68, 68); /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 10px solid rgb(68, 68, 68); /* creates padding around scroll thumb */
      }
}
.not-found-img{
  height: 50%;
}

@media  screen and (max-width: 567px) {
  .not-found-img{
    height: 30%;
  }
}