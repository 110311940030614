.drawer-notification-main {
  .ant-drawer-wrapper-body {
    .ant-drawer-header {
      padding: 16px;
    }
    .ant-drawer-body {
      &::-webkit-scrollbar {
        width: 6px; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: rgb(203, 203, 203); /* color of the tracking area */
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgb(68, 68, 68); /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 10px solid rgb(68, 68, 68); /* creates padding around scroll thumb */
      }
    }
  }
}
.main-header-cont-box {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

.search-input {
  // border-radius: 25px !important;
  padding-left: 40px !important;
  outline: none !important;
  border: none !important;
  
}
.col{
  .ant-input:focus{
    border:none !important;
  }
  .ant-input:hover{
    border: none !important;
  }
}
.search-input:focus {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  outline: none !important
}
.col {
  position: relative;
}
.search-outlined {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  z-index: 2;
}

@media screen and (max-width: 567px) {
  .welcome-msg {
    display: none !important;
  }
  .search-outlined {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    z-index: 2;
  }
}
