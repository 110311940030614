.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  .site-layout{
    .ant-layout-content{
      overflow-y: scroll !important;

      &::-webkit-scrollbar {
        width: 6px; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: rgb(203, 203, 203); /* color of the tracking area */
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgb(68, 68, 68); /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 10px solid rgb(68, 68, 68); /* creates padding around scroll thumb */
      }
      @media screen and (max-width:767px) {
        padding: 16px !important;
      }
    }
  }
  .mainLayout{
    .ant-layout{
      position: relative;
      .ant-layout-sider{
        position: inherit;
      }
    }
  }
  .sideBarWithCollapse{
    .ant-layout{
      .ant-layout-sider{
        @media screen and (max-width: 767px) {
          width: 100% !important;
          min-width: 100% !important;
          max-width: 100% !important;
          z-index: 9999 !important;
          position: fixed !important;
        }
      }
    }
  }