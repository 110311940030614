.logoBoxContainer {
  height: 0;
  overflow: hidden;
  padding-top: 16%;
  position: relative;
  cursor: pointer;
  margin: 12px 32px;
  .logoBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    img {
      object-position: center;
      width: auto;
      height: 100%;
    }
  }
}

.logoBoxContainerOneByOne {
  height: 0;
  overflow: hidden;
  padding-top: 50%;
  position: relative;
  cursor: pointer;
  margin: 12px 12px;

  .logoBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      object-position: center;
      width: 94%;
      height: 94%;
    }
  }
}
.main-side-bar {
  z-index: 1;
  height: 100vh;
  
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 2px !important;
  .ant-layout-sider-children {
    height: 100vh;
    position: relative;
    .sider-toggler-main {
      position: absolute;
      bottom: 0%;
      z-index: 999;
      width: 100%;
      display: flex;
      justify-content: center;
      height: 7%;
    }
    .sideBarMenuList {
      max-height: 86%;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      &::-webkit-scrollbar-track {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        display: none;
      }
     
      
    }
    
  }
}


@media screen and (max-width: 767px){
  .sider-toggler-main {
    display: none !important;
  }
}
// .ant-layout .ant-layout-sider {
//   box-shadow: 10px 1px 3px -8px rgba(0,0,0,1);
// }
